<template>
  <el-dialog v-model="specializedShow" title="添加层次" width="30%">
    <el-form
      label-width="100px"
      :model="specializedForm"
      :rules="specializedFormRules"
      ref="specializedRef"
    >
      <el-row>
        <el-col :span="20">
          <el-form-item label="院系名称" prop="college_id">
            <el-select
              v-model="specializedForm.college_id"
              placeholder="请选择院系"
            >
              <el-option
                v-for="item in professionalDropDown"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="20">
          <el-form-item label="所属专业" prop="parent_id">
            <el-select
              v-model="specializedForm.parent_id"
              placeholder="请选择专业"
              @focus="onTheirProfession(specializedForm.college_id)"
            >
              <el-option
                v-for="item in theirProfession"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="20">
          <el-form-item label="层次" prop="name">
            <el-select
              v-model="specializedForm.name"
              placeholder="请选择层次"
              @focus="onLayerPullDown(specializedForm.parent_id)"
            >
              <el-option
                v-for="item in hierarchicalData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="20">
          <el-form-item label="生源" prop="source">
            <el-input
              placeholder="初中毕业生 / 高中毕业生"
              v-model="specializedForm.source"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeBtn">取消 </el-button>
        <el-button type="primary" @click="onOkBtn">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
// import { professionalDropDown } from '@/mock'

import { defineProps, defineEmits, ref, watch } from 'vue'
import {
  listOfSelectionOfHospitalsAPI,
  professionalSelectionListAPI,
  listOfHierarchicalListingAPI,
  addLevelAPI,
  hierarchicalDetailsAPI,
  hierarchicalModificationAPI
} from '@/api/specialized'
import { ElMessage } from 'element-plus'
import { useVModel } from '@vueuse/core'
// From 实例
const specializedRef = ref(null)

/**
 *  props S
 */
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  levelID: {
    type: String
  },
  hierarchicalDetailsShow: {
    type: Function
  }
})
const emits = defineEmits(['update:modelValue']) // 注册 v-model 事件
const specializedShow = useVModel(props, 'modelValue', emits)

watch(
  () => props.levelID,
  (val) => {
    onHierarchicalDetails(val)
  }
)

const onHierarchicalDetails = async (id) => {
  const data = await hierarchicalDetailsAPI({ id })
  console.log(data)
  specializedForm.value = data
}
/**
 *  props E
 */

/**
 *  表单 S
 */
// 院系
const professionalDropDown = ref([])
const onListOfSelectionOfHospitals = async () => {
  const data = await listOfSelectionOfHospitalsAPI()

  professionalDropDown.value = data
}
onListOfSelectionOfHospitals()

// 所属专业
const theirProfession = ref([])
const onTheirProfession = async (id) => {
  const data = await professionalSelectionListAPI({
    college_id: id
  })
  theirProfession.value = data
}

// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}

// 添加表单
const specializedForm = ref({
  college_id: '',
  parent_id: '',
  name: '',
  source: ''
})

// 验证规则
const specializedFormRules = ref({
  college_id: [
    {
      required: true,
      trigger: 'blur',
      message: '请选择院系名'
    }
  ],
  parent_id: [
    {
      required: true,
      trigger: 'blur',
      message: '请选择院系名'
    }
  ],

  name: [
    {
      required: true,
      trigger: 'blur',
      message: '请选择层次'
    }
  ],
  source: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入生源'
    }
  ]
})

// 取消
const closeBtn = () => {
  specializedRef.value.resetFields()
  specializedForm.value = {
    college_id: '',
    parent_id: '',
    name: '',
    source: ''
  }
  specializedShow.value = false
}

// 确认
const onOkBtn = () => {
  specializedRef.value.validate(async (valid) => {
    if (!valid) return
    if (specializedForm.value.id) {
      await hierarchicalModificationAPI(specializedForm.value)
      ElMessage.success('修改层次成功')
    } else {
      // 触发添加 api
      await addLevelAPI(specializedForm.value)
      ElMessage.success('添加层次成功')
    }

    props.hierarchicalDetailsShow()
    specializedShow.value = false
    specializedForm.value = {
      college_id: '',
      parent_id: '',
      name: '',
      source: ''
    }
  })
}

/**
 *  表单 S
 */
</script>

<style>
.el-select {
  width: 100%;
}
</style>
