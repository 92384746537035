<template>
  <div>
    <el-row :gutter="20">
      <!-- 专业 -->
      <el-col :span="6">
        <el-card class="card">
          <template #header>
            <div class="card-header">
              <h3>专业名称</h3>
              <el-button type="text" @click="professionalManagement">
                专业管理
              </el-button>
            </div>
          </template>

          <div
            v-for="item in professionalListArr"
            :key="item.id"
            class="text item"
          >
            <el-button
              plain
              style="border: none"
              @click="hierarchicalDetails(item)"
            >
              <el-icon><CollectionTag /></el-icon> {{ item.name }}
            </el-button>
          </div>
        </el-card>
      </el-col>

      <!-- 层次 -->
      <el-col :span="18">
        <h3>
          {{
            btnShow === HIERARCHICAL ? `${levelName} - 层次管理` : '专业管理'
          }}
        </h3>
        <el-divider />
        <el-row>
          <el-col :span="3">
            <el-button type="primary" @click="addProfessional">
              添加专业</el-button
            ></el-col
          >
          <el-col :span="3" v-if="btnShow === HIERARCHICAL">
            <el-button @click="addLevelBtn"> 添加层次 </el-button></el-col
          >
        </el-row>
        <!-- 表格 -->
        <el-row>
          <el-table
            :data="listData"
            border
            style="width: 100%; margin-top: 10px"
            :header-cell-style="{ background: '#f2f2f2' }"
          >
            <el-table-column prop="id" label="ID" />
            <!--  -->
            <el-table-column
              :label="btnShow === HIERARCHICAL ? '层次' : '名称'"
              show-overflow-tooltip
            >
              <template v-slot="{ row }">
                {{ btnShow === HIERARCHICAL ? row.level_name : row.name }}
              </template>
            </el-table-column>
            <el-table-column prop="college_name" label="所属院系" />
            <el-table-column prop="domain_name" label="所属专业" />

            <el-table-column label="操作" align="center">
              <template #default="{ row }">
                <el-popconfirm
                  v-if="row.is_signup === 1 && btnShow === PROFESSIONAL"
                  title="是否禁用该专业?"
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  @confirm="onEditBtnA(row.id)"
                >
                  <template #reference>
                    <el-button type="primary" size="small">
                      禁用报名
                    </el-button>
                  </template>
                </el-popconfirm>
                <el-popconfirm
                  v-if="row.is_signup === 0 && btnShow === PROFESSIONAL"
                  title="是否启用该专业?"
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  @confirm="onEditBtnB(row.id)"
                >
                  <template #reference>
                    <el-button type="danger" size="small"> 启用报名 </el-button>
                  </template>
                </el-popconfirm>
                <el-button type="primary" size="small" @click="onEditBtn(row)"
                  >编辑</el-button
                >
                <el-popconfirm
                  v-if="btnShow === PROFESSIONAL"
                  title="是否删除该专业?"
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  @confirm="deleteBtn(row.id)"
                >
                  <template #reference>
                    <el-button type="danger" size="small"> 删除 </el-button>
                  </template>
                </el-popconfirm>

                <el-popconfirm
                  v-else
                  title="是否删除该层次?"
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  @confirm="layerDeletion(row.id)"
                >
                  <template #reference>
                    <el-button type="danger" size="small"> 删除 </el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <!-- 分页 -->
        <el-row style="margin-top: 20px">
          <el-col>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="search.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="search.total"
            >
            </el-pagination>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <!-- 添加专业 / 修改专业 -->
    <professional-level
      v-model="professionalShow"
      :specializedItemData="specializedItemData"
      :onProfessionalListPaging="onProfessionalListPaging"
      :onProfessionalList="onProfessionalList"
    ></professional-level>

    <!-- 添加层次 / 修改层次 -->
    <level-add-edit
      v-model="levelShow"
      :levelID="levelID"
      :hierarchicalDetailsShow="hierarchicalDetailsShow"
    ></level-add-edit>
  </div>
</template>

<script setup>
import {
  professionalListAPI,
  professionalListPagingAPI,
  layerListAPI,
  deleteProfessionalAPI,
  deleteLevelAPI,
  domainStateAPI
} from '@/api/specialized'
import { ElMessage } from 'element-plus'
import { ref } from 'vue'
import professionalLevel from './components/professionalLevel.vue'
import levelAddEdit from './components/level—add-edit.vue'
// 0: 专业管理
const PROFESSIONAL = 'PROFESSIONAL'
// 1：层次管理
const HIERARCHICAL = 'HIERARCHICAL'

// 层次管理显示
const btnShow = ref(PROFESSIONAL)
// 层次专业名
const levelName = ref('')
// 添加专业修改专业弹窗
const professionalShow = ref(false)

/** 专业列表 S */
// 专业列表数据源
const professionalListArr = ref([])
// 获得专业列表数据
const onProfessionalList = async () => {
  const data = await professionalListAPI()
  professionalListArr.value = data
}
onProfessionalList()

/** 专业列表 E */

/**
 *  搜索 S
 */
const search = ref({
  parent_id: '',
  page: 1,
  limit: 10,
  total: 0
})

// 专业管理
const professionalManagement = () => {
  // 专业名
  btnShow.value = PROFESSIONAL
  onProfessionalListPaging()
}

//

/**
 *  搜索 E
 */

/*
 * 表格 S
 */

// 表格数据源
const listData = ref([])
// 专业管理表格数据
const onProfessionalListPaging = async () => {
  search.value.parent_id = ''
  const { data, total } = await professionalListPagingAPI(search.value)
  listData.value = data
  search.value.total = total
}
onProfessionalListPaging()

// 层次表格数据过度
const layerDependenceItem = ref({})
const hierarchicalDetails = (item) => {
  layerDependenceItem.value = item
  hierarchicalDetailsShow()
}

// 层次表格数据显示
const hierarchicalDetailsShow = async () => {
  btnShow.value = HIERARCHICAL
  levelName.value = layerDependenceItem.value.name
  search.value.parent_id = layerDependenceItem.value.id
  const { data, total } = await layerListAPI(search.value)
  listData.value = data
  search.value.total = total
}

const specializedItemData = ref({})
const levelID = ref('')
// 编辑专业
const onEditBtn = (row) => {
  if (!Number(row.parent_id)) {
    specializedItemData.value = row
    professionalShow.value = true
  } else {
    levelID.value = row.id
    levelShow.value = true
  }
}
// 专业删除
const deleteBtn = async (id) => {
  await deleteProfessionalAPI({ id })
  ElMessage.success('删除专业成功')
  onProfessionalListPaging()
  onProfessionalList()
}

// 禁用专业
const onEditBtnA = async (id) => {
  await domainStateAPI({ id: id, is_signup: 0 })
  ElMessage.success('禁用成功')
  onProfessionalListPaging()
  onProfessionalList()
}
// 启用专业
const onEditBtnB = async (id) => {
  await domainStateAPI({ id: id, is_signup: 1 })
  ElMessage.success('启用成功')
  onProfessionalListPaging()
  onProfessionalList()
}
// 层次删除
const layerDeletion = async (id) => {
  await deleteLevelAPI({ id })
  ElMessage.success('删除层次成功')
  hierarchicalDetailsShow()
  onProfessionalList()
}

// 层次
const levelShow = ref(false)
const addLevelBtn = () => {
  levelShow.value = true
}

/*
 * 表格 E
 */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onProfessionalListPaging()
  onProfessionalList()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onProfessionalListPaging()
  onProfessionalList()
}

/**
 *  分页 E
 */

/**
 *  专业组件 S
 */

// 添加专业
const addProfessional = () => {
  specializedItemData.value = {}
  professionalShow.value = true
}

/**
 *  专业组件 E
 */
</script>

<style lang="scss" scoped>
.card {
  height: 90vh;
  overflow-y: scroll;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.text {
  font-size: 14px;
  padding: 5px 0;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c8c8c8;
}
</style>
