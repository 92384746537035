<template>
  <el-dialog
    v-model="specializedShow"
    :title="specializedItemData.id ? '修改专业' : '添加专业'"
    width="30%"
  >
    <el-form
      label-width="100px"
      :model="specializedForm"
      :rules="specializedFormRules"
      ref="specializedRef"
    >
      <el-row>
        <el-col :span="14">
          <el-form-item label="院系名称" prop="college_id">
            <el-select
              v-model="specializedForm.college_id"
              placeholder="请选择院系"
            >
              <el-option
                v-for="item in professionalDropDown"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="14">
          <el-form-item label="专业名称" prop="name">
            <el-input
              placeholder="请输入专业名称"
              v-model="specializedForm.name"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeBtn">取消 </el-button>
        <el-button type="primary" @click="onOkBtn">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
// import { professionalDropDown } from '@/mock'

import { defineProps, defineEmits, ref, watch } from 'vue'
import {
  listOfSelectionOfHospitalsAPI,
  addProfessionalAPI,
  modifyTheMajorAPI
} from '@/api/specialized'
import { useVModel } from '@vueuse/core'
import { ElMessage } from 'element-plus'

// From 实例
const specializedRef = ref(null)

/**
 *  props S
 */
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },

  specializedItemData: {
    type: Object
  },

  onProfessionalListPaging: {
    type: Function
  },
  onProfessionalList: {
    type: Function
  }
})
const emits = defineEmits(['update:modelValue']) // 注册 v-model 事件
const specializedShow = useVModel(props, 'modelValue', emits)

watch(
  () => props.specializedItemData,
  (val) => {
    console.log(val)
    specializedForm.value = val
  }
)

/**
 *  props E
 */

/**
 *  表单 S
 */

const professionalDropDown = ref([])
const onListOfSelectionOfHospitals = async () => {
  const data = await listOfSelectionOfHospitalsAPI()
  professionalDropDown.value = data
}
onListOfSelectionOfHospitals()

// 添加表单
const specializedForm = ref({
  college_id: '',
  name: ''
})

// 验证规则
const specializedFormRules = ref({
  college_id: [
    {
      required: true,
      trigger: 'blur',
      message: '请选择院系名'
    }
  ],
  name: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入专业名'
    }
  ]
})

// 取消
const closeBtn = () => {
  specializedRef.value.resetFields()
  specializedForm.value = {}
  props.onProfessionalListPaging()
  props.onProfessionalList()
  specializedShow.value = false
}

// 确认
const onOkBtn = () => {
  specializedRef.value.validate(async (valid) => {
    if (!valid) return
    try {
      console.log(specializedForm.value)
      if (specializedForm.value.id) {
        await modifyTheMajorAPI(specializedForm.value)
        ElMessage.success('编辑专业成功。')
      } else {
        // 触发添加 api
        await addProfessionalAPI(specializedForm.value)
        ElMessage.success('添加专业成功。')
      }
      props.onProfessionalListPaging()
      props.onProfessionalList()
    } catch (error) {
      console.log(error)
    } finally {
      specializedShow.value = false
    }
  })
}

/**
 *  表单 S
 */
</script>

<style></style>
